import request from '@/utils/request'

export function getOrderNum(data) { return request({ url: 'order-web-api/supply/order/queryServiceCountLists', method: 'POST', data }) } //获取统计服务数量
export function getOrderQuery(data) { return request({ url: 'order-web-api/supply/order/getServiceList', method: 'POST', data }) } //获取订单分页列表
export function getOrderDetail(data) { return request({ url: 'order-web-api/supply/order/getServiceDetail', method: 'POST', data }) } //获取订单详情
export function getDetailAddOrderList(data) { return request({ url: 'order-web-api/supply/order/getOrderLists', method: 'POST', data }) } //获取加购订单列表
export function getDetailServerRecord(data) { return request({ url: 'order-web-api/supply/order/getServiceRecordList', method: 'POST', data }) } //获取订单详情服务记录
export function getDetailServerProtocol(data) { return request({ url: 'order-web-api/supply/order/getOrderTreatyRecod', method: 'POST', data }) } //获取订单详情服务器协议
export function getDetailPhotos(data) { return request({ url: 'order-web-api/supply/order/getServiceUserPhotos', method: 'POST', data }) } //获取订单详情照片库
export function getDetailPhone(data) { return request({ url: 'order-web-api/supply/order/getPortDelPhone', method: 'POST', data }) } //获取订单详情照片库 联系电话
export function getDetailEvaluation(data) { return request({ url: 'evaluation-web-api/supply/evaluation/getEvaluationInfoByServiceId', method: 'POST', data }) } // 获取订单评价详情
export function evaluateReply(data){  return request({ url: 'evaluation-web-api/supply/evaluation/saveEvaluationReply', method: 'POST', data })} //评价回复


export function getAddProductList(data) { return request({ url: 'product-web-api/supplier/product/findBaseAdditionList', method: 'POST', data }) } //获取加购产品列表
export function getAddProductTypeList(data) { return request({ url: 'product-web-api/supplier/productCategory/list', method: 'POST', data }) } //获取加购产品列表
export function addProduct(data) { return request({ url: 'order-web-api/supply/order/addition', method: 'POST', data }) } //加购产品


export function getOrderRecordList(data) { return request({ url: 'order-web-api/supply/order/getOrderRecordList', method: 'POST', data }) } //获取加购订单详情订单记录
export function getOrderRecordDetail(data) { return request({ url: 'order-web-api/supply/order/getOrderDetail', method: 'POST', data }) } //获取加购订单详情
export function getOrderRecordRefundDetail(data) { return request({ url: 'order-web-api/supply/order/queryOrderRefund', method: 'POST', data }) } //获取加购订单详情售后记录详情

export function getOrderPayLink(data) { return request({ url: 'order-web-api/supply/order/getOrderPayListByOrderId', method: 'POST', data }) } //获取订单付款链接






export function enterOrder(data) { return request({ url: 'order-web-api/supply/order/confirmOrder', method: 'POST', data }) } //确认接单
export function refuseOrder(data) { return request({ url: 'order-web-api/supply/order/refuseOrder', method: 'POST', data }) } //拒绝接单
export function enterStore(data) { return request({ url: 'order-web-api/supply/order/confirmStore', method: 'POST', data }) } //确认到店
export function enterFinishServer(data) { return request({ url: 'order-web-api/supply/order/confirmFinish', method: 'POST', data }) } //确认完成服务
export function uploadImg(data) { return request({ url: 'order-web-api/supply/order/uploadPic', method: 'POST', data }) } //上传照片
export function cancelOrder(data) { return request({ url: 'order-web-api/supply/order/cancel', method: 'POST', data }) } //取消订单







